<template>
  <div style="overflow-y: auto; max-height: 500px;overflow-x: hidden; margin-top: -20px">
    <el-select v-model="phase" placeholder="Chọn mốc tích lũy">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; margin-top: 20px">
      <div>
        <h4 style="margin-bottom: 10px; font-size: 14px">Thông tin chi tiết:</h4>
        <el-table
            :data="dataDetails"
            border
            style="width: fit-content">
          <el-table-column
              prop="label"
              label="Giai đoạn"
              width="260">
          </el-table-column>
          <el-table-column
              prop="value"
              label="Được chuyển nhượng/Bán"
              width="220">
          </el-table-column>
        </el-table>
      </div>
      <div>
        <h4 style="margin-bottom: 10px; font-size: 14px">Giá trị trái phiếu tích lũy từng năm:</h4>
        <el-table
            :data="dataBondYear"
            border :show-header="false"
            style="width: fit-content">
          <el-table-column
              prop="label"
              width="260">
          </el-table-column>
          <el-table-column
              prop="value"
              width="220">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {BOND_VALUE_DETAIL} from "../../../../core/services/store/service.module";
import {formatDate, formatMoneyVND} from "../../../../core/filters";

export default {
  name: 'DetailBond',
  data() {
    return {
      phase: 0,
      options: [
        {
          label: `Mốc tích lũy 1`,
          value: 1
        },
        {
          label: `Mốc tích lũy 2`,
          value: 2
        },
        {
          label: `Mốc tích lũy 3`,
          value: 3
        },
        {
          label: `Mốc tích lũy 4`,
          value: 4
        },
        {
          label: `Mốc tích lũy 5`,
          value: 5
        }
      ],
      dataBonds: [],
      dataDetails: [],
      dataBondYear: [],
    }
  },
  watch: {
    phase(val) {
      if (val) {
        this.dataDetails = []
        this.filterBond()
      }
    }
  },
  methods: {
    formatMoneyVND,
    formatDate,
    async detailBondValue() {
      this.loadingDetail = true;
      this.$store.dispatch(BOND_VALUE_DETAIL).then((data) => {
        this.dataBonds = data?.data?.map(item => {
          this.phase = item?.milestone
          return item
        })
        this.filterBond()
      }).finally(() => {
        this.loadingDetail = false;
      })
    },
    filterBond() {
      if (this.dataBonds.length > 0) {
        const newData = this.dataBonds.filter(item => item?.milestone === this.phase);
        if (newData.length > 0) {
          newData?.map(e => {
            this.getDetails(e)
          })
        } else {
          this.getDetails()
        }
        this.dataBondYear = [1, 2, 3, 4, 5, 6, 7].map(item => {
          return {
            label: `Năm ${item}`,
            value: this.getDataForYear(item, newData[0])
          }
        })
      }
    },
    getDataForYear(year, data) {
      const item = data?.items.find(item => item?.year_number === year);
      if (item?.amount) {
        return this.formatMoneyVND(item.amount);
      } else {
        return 'Chưa có dữ liệu';
      }
    },
    getDetails(data) {
      this.dataDetails = ['start_date', 'end_date', 'allowed_sale_date', 'amount_total', 'amount_sold', 'amount_balance'].map(item => {
        return {
          label: this.convertKeyToString(item),
          value: data ? this.formatData(data, item) : 'Chưa có dữ liệu',
        }
      })
    },
    formatData(data, key) {
      switch (key) {
        case 'start_date':
        case 'end_date':
        case 'allowed_sale_date':
          return this.formatDate(data[key])
        case 'amount_total':
        case 'amount_sold':
        case 'amount_balance':
          return this.formatMoneyVND(data[key])
        default:
          return 'Chưa có dữ liệu'
      }
    },
    convertKeyToString(key) {
      if (key) {
        switch (key) {
          case "start_date":
            return 'Thời điểm bắt đầu tích lũy'
          case "end_date":
            return 'Thời điểm trái phiếu có hiệu lực'
          case "allowed_sale_date":
            return 'Thời điểm được chuyển nhượng/bán'
          case "amount_total":
            return 'Tổng giá trị trái phiếu'
          case "amount_sold":
            return 'Số lượng TP đã bán/chuyển nhượng'
          case "amount_balance":
            return 'Số lượng TP còn lại'
        }
      }
    },
  },
  mounted() {
    this.detailBondValue()
  }
}
</script>