<template>
    <div class="page-wrapper" v-if="isAuthenticated">
        <!-- begin:: Header Mobile -->
        <edutalk-loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></edutalk-loader>
        <!-- end:: Header Mobile -->
        <div class="page-inner">
            <!-- begin:: Aside Left -->
            <edutalk-aside v-if="asideEnabled"></edutalk-aside>
            <!-- end:: Aside Left -->
            <div class="page-content-wrapper">
                <!-- begin:: Header -->
                <edutalk-header></edutalk-header>
                <!-- end:: Header -->
                <!-- begin:: Content -->
                <!-- the #js-page-content id is needed for some plugins to initialize -->
                <main id="js-page-content" role="main" class="page-content">
                    <!-- begin:: Content Head -->
                    <edutalk-breadcrumbs v-if="subheaderDisplay" v-bind:breadcrumbs="breadcrumbs" v-bind:title="pageTitle"></edutalk-breadcrumbs>
                    <edutalk-title v-bind:title="pageTitle" v-bind:icon="pageIcon"></edutalk-title>
                    <!-- end:: Content Head -->
                    <router-view></router-view>
                </main>
                <!-- this overlay is activated only when mobile menu is triggered -->
                <!--        <div class="page-content-overlay" data-action="toggle" data-class="mobile-nav-on"></div> &lt;!&ndash; END Page Content &ndash;&gt;-->
                <!-- BEGIN Page Footer -->
<!--                <KTFooter :version="version"></KTFooter>-->
                <edutalk-footer></edutalk-footer>
            </div>
            <!--      <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>-->
            <!--      <KTScrollTop></KTScrollTop>-->
        </div>
        <div style="position:fixed; bottom:100px; right: 18px">
          <img src="/media/logo-zalo.png" alt="logo-zalo" @click="openZalo" class="bounce-animation" style="width: 40px; height: 40px;">
        </div>
<!--        <quick-menu></quick-menu>-->
        <div class="hideBackDrop hide" @click="closeAside"></div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import EdutalkLoader from "@/views/content/edutalk-loader.vue";
    import EdutalkAside from "@/views/layout/aside/edutalk-aside.vue";
    import EdutalkHeader from "@/views/layout/header/edutalk-header.vue";
    import EdutalkBreadcrumbs from "@/views/layout/header/edutalk-breadcrumbs.vue";
    import EdutalkTitle from "@/views/layout/header/edutalk-title.vue";
    import EdutalkFooter from "@/views/layout/footer/edutalk-footer.vue";
    import QuickMenu from "@/views/layout/footer/edutalk-quick-menu.vue";
    // import KTFooter from "@/view/layout/footer/Footer.vue";
    // import HtmlClass from "@/core/services/htmlclass.service";
    // import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    // import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
    // import KTScrollTop from "@/view/layout/extras/ScrollTop";
    import {
        ADD_BODY_CLASSNAME,
        REMOVE_BODY_CLASSNAME
    } from "@/core/services/store/htmlclass.module.js";
    import store from "@/core/services/store";

    export default {
        name: "Layout",
        components: {
            EdutalkLoader,
            EdutalkAside,
            EdutalkHeader,
            EdutalkBreadcrumbs,
            EdutalkTitle,
            EdutalkFooter,
        },
        beforeMount() {
            // show page loading
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

            // initialize html element classes
            // HtmlClass.init(this.layoutConfig());
        },
        data() {
            return {
                version: ''
            };
        },
        mounted() {
            if (!this.isAuthenticated) {
                this.$router.push({ name: "login" });
            }
            // Simulate the delay page loading
            setTimeout(() => {
                // Remove page loader after some time
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }, 1000);
        },
        methods: {
            closeAside: function (event) {
                if (document.body.classList.contains("mobile-nav-on")) {urre
                    document.body.classList.remove("mobile-nav-on");
                }
                let backdrop = document.querySelector(".hideBackDrop");
                if (backdrop.classList.contains("add")) {
                    backdrop.classList.remove('add');
                    backdrop.classList.add('hide');
                }
            },
            openZalo() {
              let currentUser = store.getters.currentUser;
              switch (currentUser?.center_teacher[0]?.center?.id) {
                case 295:
                  window.open('https://zalo.me/ecoach', 'blank')
                  break;
                case 516:
                  window.open('https://zalo.me/ieltsmentor', 'blank')
                  break;
                default:
                  window.open('https://zalo.me/edutalkholdings', 'blank')
                  break;
              }

            }
        },
        computed: {
            ...mapGetters([
                "isAuthenticated",
                "breadcrumbs",
                "pageTitle",
                "pageIcon",
                "layoutConfig"
            ]),

            /**
             * Check if the page loader is enabled
             * @returns {boolean}
             */
            loaderEnabled() {
                return !/false/.test(this.layoutConfig("loader.type"));
            },

            /**
             * Check if container width is fluid
             * @returns {boolean}
             */
            contentFluid() {
                return this.layoutConfig("content.width") === "fluid";
            },

            /**
             * Page loader logo image using require() function
             * @returns {string}
             */
            loaderLogo() {
                return process.env.BASE_URL + this.layoutConfig("loader.logo");
            },

            /**
             * Check if the left aside menu is enabled
             * @returns {boolean}
             */
            asideEnabled() {
                return !!this.layoutConfig("aside.self.display");
            },

            /**
             * Set the right toolbar display
             * @returns {boolean}
             */
            toolbarDisplay() {
                // return !!this.layoutConfig("toolbar.display");
                return true;
            },

            /**
             * Set the subheader display
             * @returns {boolean}
             */
            subheaderDisplay() {
                return !!this.layoutConfig("subheader.display");
            }
        }
    };
</script>
<style scoped>
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.bounce-animation {
  cursor: pointer;
  animation: bounce 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
</style>
